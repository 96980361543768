jQuery(document).ready(function($) {
    //console.log("Theme customization scripts loaded!");
});

// VARIABLES
// Responsive breakpoints
let mobile_breakpoint = 600;
let tablet_breakpoint = 768;
let laptop_breakpoint = 1280;
let desktop_breakpoint = 1920;

// FUNCTIONS
// FAQ dropdown behavior
var coll = document.getElementsByClassName("collapsible");
for (var i = 0; i < coll.length; i++) {
    coll[i].addEventListener("click", function() {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    });
}

// Fade in observer
document.addEventListener("DOMContentLoaded", function () {
    // Check if we're inside the Gutenberg editor
    if (document.body.classList.contains('block-editor-page')) {
        return; // Stop execution inside the editor
    }

    const fadeInElements = document.querySelectorAll("h1, h2, h3, h4, h5, h6, p, a, img, li, .wp-block-image img, input");

    const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("fade-in-visible");
                    observer.unobserve(entry.target); // Stop observing once it's visible
                }
            });
        },
        { threshold: 0.1 }
    );

    fadeInElements.forEach(element => observer.observe(element));
});

// TOP BAR + HEADER VISIBILITY LOGIC
document.addEventListener("DOMContentLoaded", function () {
    let topbar = document.querySelector(".topbar");
    let header = document.querySelector("header");
    let main = document.querySelector("main");
    let prevScrollY = window.scrollY;

    function getTopbarHeight() {
        return topbar ? topbar.offsetHeight : 0;
    }

    function handleScroll() {
        let currentScrollY = window.scrollY;

        if (currentScrollY === 0) {
            // If scrolled to the very top, ensure topbar is visible
            topbar?.classList.remove("hide");
            header?.classList.remove("header-up");
            main.style.marginTop = `${getTopbarHeight() + 30}px`;
        } else if (currentScrollY > prevScrollY) {
            // Scrolling down - hide topbar, move header up
            topbar?.classList.add("hide");
            header?.classList.add("header-up");
            main.style.marginTop = "30px"; // Adjusted for header only
        } else {
            // Scrolling up - show topbar again
            topbar?.classList.remove("hide");
            header?.classList.remove("header-up");
            main.style.marginTop = `${getTopbarHeight() + 30}px`;
        }

        prevScrollY = currentScrollY;
    }

    if (topbar) {
        window.addEventListener("scroll", handleScroll, { passive: true });
    } else {
        header.style.top = "0";
        main.style.marginTop = "30px"; // Adjust if needed
    }
});

// FORCE TRANSLATIONS ON CERTAIN STRINGS (NOT RECOMMENDED, BUT WPML IS UNABLE TO FIND THESE)
jQuery(document).ready(function ($) {
    // Finnish translations
    if ($('html').attr('lang') === 'fi') {  // Apply these only if WPML language is set to Finnish!
        function updateLabels() {
            // Checkout, Cart, and My Account labels
            $('.wc-block-components-totals-item__label').each(function () {
                const labelText = $(this).text().trim();
                if (labelText === "Total due today") {
                    $(this).text("Maksettavaa yhteensä");
                }
                if (labelText === "Monthly recurring total") {
                    $(this).text("Kuukausimaksu");
                }
                if (labelText === "Total") {
                    $(this).text("Yhteensä");
                }
                if (labelText === "Recurring total every 2nd month") {
                    $(this).text("Toistuu joka 2. kuukausi");
                }
                if (labelText === "Recurring total every month") {
                    $(this).text("Toistuu joka kuukausi");
                }
            });

            // Cart: Delivery-title
            $(".wc-block-components-totals-item__label").each(function () {
                if ($(this).text().trim() === "Delivery") {
                    $(this).text("Toimitus");
                }
            });
            
            // Handle "every kuukausi" in the order total row
            $('td.woocommerce-orders-table__cell-order-total, .product-total, .shop_table td').each(function () {
                const $cell = $(this);
                const cellText = $cell.text().trim();

                // Check for "every kuukausi" and replace it
                if (cellText.includes("every kuukausi")) {
                    $cell.contents().filter(function() {
                        return this.nodeType === 3; // Target text nodes only
                    }).each(function() {
                        if ($(this).text().trim() === "every kuukausi") {
                            $(this).replaceWith("joka kuukausi"); // Replace "every kuukausi"
                        }
                    });
                }
            });
            
            /*
            // Handle "every month" and "every kuukausi" in the pricing summary
            // THIS CRASHES SUBSCRIPTION PLUGIN: DO NOT ENABLE UNTIL THIS CODE HAS BEEN REWRITTEN!
            $('.price.wc-block-components-product-price').each(function () {
                const $priceElement = $(this);
                $priceElement.contents().filter(function () {
                    return this.nodeType === 3; // Node type 3 is text node
                }).each(function () {
                    // Replace "every month" with "joka kuukausi"
                    if ($(this).text().trim() === "every month") {
                        $(this).replaceWith("joka kuukausi");
                    }
                });
            });
            */

            // Translate button text (keeping SVG intact)
            $('.wc-block-components-panel__button').each(function () {
                const $button = $(this);
                const textNode = $button.contents().filter(function () {
                    return this.nodeType === 3; // Node type 3 is text node
                });

                if (textNode.length && textNode[0].nodeValue.trim() === "Details") {
                    textNode[0].nodeValue = "Lisätiedot"; // Replace text while keeping the SVG intact
                }
            });

            // Translate "Starting:" text with the date
            $('.wc-block-components-totals-item__description').each(function () {
                const text = $(this).text().trim();
                if (text.startsWith("Starting:")) {
                    const date = text.replace("Starting:", "").trim(); // Extract the date
                    $(this).html(`<span>Alkaen: ${date}</span>`); // Replace with Finnish translation
                }
            });

            // Translate "Includes:"
            $('.wc-block-components-product-details__includes .wc-block-components-product-details__name').each(function () {
                if ($(this).text().trim() === "Includes:") {
                    $(this).text("Sisältää:");
                }
            });

            // Handle "month" and replace with "kuukausi"
            $('.wc-block-components-product-badge.wc-block-components-sale-badge').each(function () {
                const $badge = $(this);
                
                // Replace "month" with "kuukausi" directly in the text content
                const text = $badge.text().trim();
                if (text.includes("month")) {
                    const updatedText = text.replace("month", "kuukausi");
                    $badge.text(updatedText); // Set the updated text back
                }
            });

            // Find the element containing "Due today" and remove it
            $('.price.wc-block-components-product-price').each(function () {
                const $priceElement = $(this);

                // Find the text node for "Due today"
                const textNode = $priceElement.contents().filter(function () {
                    return this.nodeType === 3; // Node type 3 is a text node
                });

                // Remove the "Due today" text
                if (textNode.length && textNode[0].nodeValue.trim() === "Due today") {
                    textNode[0].nodeValue = ""; // Set the text to an empty string to remove it
                }
            });

            // Translate "Choose a new subscription" text
            $('.wc-block-components-notice-banner__content').each(function () {
                const text = $(this).text().trim();
                if (text === "Choose a new subscription.") {
                    $(this).text("Valitse uusi tilaus.");
                }
            });

            // Translate "You can not switch to the same subscription" text
            $('.wc-block-components-notice-banner__content').each(function () {
                const text = $(this).text().trim();
                if (text === "You can not switch to the same subscription.") {
                    $(this).text("Et voi vaihtaa samaan tilaukseen.");
                }
            });

            // If WBW filters return null
            $('.wp-block-woocommerce-product-collection-no-results strong').each(function(){
                var currentText = $(this).text();
                if(currentText.indexOf("No results found") !== -1) {
                  $(this).text("Ei hakutuloksia");
                }
            });
        }

        updateLabels();

        // Use a MutationObserver to handle dynamically added elements
        const observer = new MutationObserver(() => {
            updateLabels();
        });

        // Observe the body for changes
        observer.observe(document.body, { childList: true, subtree: true });
    }
});