jQuery(document).ready(function($) {
    //console.log("Custom WooCommerce scripts loaded!");
});

// Force min/max error notice under actual content instead outside of body
document.addEventListener("DOMContentLoaded", function () {
    let noticeBanner = document.querySelector(".wc-block-components-notice-banner");
    let mainContent = document.querySelector(".wp-block-woocommerce-store-notices"); // Adjust this selector

    if (noticeBanner && mainContent) {
        mainContent.prepend(noticeBanner); // Moves it inside your main content area
    }
});

// Hide shipping method confirmation notification banner from appearing all the time
document.addEventListener("DOMContentLoaded", function () {
    function hideShippingZoneNotice() {
        let notices = document.querySelectorAll(".wc-block-components-notice-banner");
        
        notices.forEach(function (notice) {
            if (notice.textContent.includes("vyöhykettä")) {
                notice.style.display = "none";
            }
        });
    }

    // Run initially
    hideShippingZoneNotice();

    // Observe changes in the document for dynamically added notices
    const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation.addedNodes.length) {
                hideShippingZoneNotice();
            }
        });
    });

    observer.observe(document.body, { childList: true, subtree: true });
});

/*********************
PRODUCT CATEGORY PAGES
**********************/

// Discount status indicator on Woo store pages
/*
jQuery(document).ready(function ($) {
    if ($("body").hasClass("woocommerce-shop")) {
        // Variables (rather these be dynamic straight from the plugin, but what you gonna do...)
        const discountSteps = [
            { amount: 25 },   // Minimum order amount
            { amount: 45, discount: 10 },   // Spend 45€ to unlock 10% discount
            { amount: 60 },   // Spend 60€ to unlock free shipping
            { amount: 65, discount: 20 },   // Spend 65€ to unlock 20% discount
        ];

        var wc_checkout_url = "/checkout"; // Default checkout URL
        // Check if the page language is Finnish
        if ($("html").attr("lang") === "fi") {
            wc_checkout_url = "/kassa"; // Change checkout URL for Finnish
        }

        function createFixedCartElement() {
            if ($('#fixed-cart-total').length === 0) {  // Avoid duplicating the element
                $('body').append(
                    '<div id="fixed-cart-total" class="fixed-cart-total">' +
                        '<a href="' + wc_checkout_url + '" id="fixed-cart-link">' + // Make the whole element a link
                            '<span id="discount-message"></span>' +  // Placeholder for the discount message
                        '</a>' +
                    '</div>'
                );
            }        
        }        

        function updateVisibleCartTotal() {
            var cartTotals = wp.data.select('wc/store/cart').getCartTotals();

            if (cartTotals) {
                var totalItems = parseFloat(cartTotals.total_items) / 100; // Convert cents to euros
                var totalTax = parseFloat(cartTotals.total_tax) / 100; // Convert tax
                var totalInDecimal = totalItems + totalTax; // Final price

                console.log("Total (with tax):", totalInDecimal.toFixed(2) + "€"); // Ensure two decimals
            }

            // Update the fixed bottom cart total dynamically
            //$('#cart-total-price').text(formattedPrice);

            // Initialize discount message variable
            var discountMessage = '';
            var currentClass = ''; // Default class

            // Logic for minimum order and discount messages
            if (totalInDecimal < discountSteps[0].amount) {
                var amountLeft = (discountSteps[0].amount - totalInDecimal).toFixed(2);
                discountMessage = myTranslations.spendMoreForDiscount_0.replace('%s', amountLeft); // No percentage display required
            }
            else if (totalInDecimal < discountSteps[1].amount) {
                var amountLeft = (discountSteps[1].amount - totalInDecimal).toFixed(2);
                discountMessage = myTranslations.spendMoreForDiscount_1.replace('%s', amountLeft).replace('%s', discountSteps[1].discount);
                currentClass = 'discount-level-0';
            } else if (totalInDecimal < discountSteps[2].amount) {
                var amountLeft = (discountSteps[2].amount - totalInDecimal).toFixed(2);
                discountMessage = myTranslations.spendMoreForDiscount_2.replace('%s', amountLeft); // Free shipping message, no percentage display required
                currentClass = 'discount-level-1';
            } else if (totalInDecimal < discountSteps[3].amount) {
                var amountLeft = (discountSteps[3].amount - totalInDecimal).toFixed(2);
                discountMessage = myTranslations.spendMoreForDiscount_3.replace('%s', amountLeft).replace('%s', discountSteps[3].discount);
                currentClass = 'discount-level-2';
            } else {
                discountMessage = myTranslations.maxDiscountReached;
                currentClass = 'discount-level-3';
            }

            // Display the appropriate discount message
            $('#discount-message').text(discountMessage);

            // Apply the correct class based on discount level
            var cartContainer = $('#fixed-cart-total');
            cartContainer.removeClass('discount-level-1 discount-level-2 discount-level-3').addClass(currentClass);
        }

        // Call the function to create the element if on correct page
        if (typeof wp.data !== 'undefined') {   // Create the element only if we can get the price data
            createFixedCartElement();
        }

        // Listen for WooCommerce cart updates
        $(document.body).on('wc-blocks_added_to_cart updated_wc_div updated_cart_totals updated_checkout', function () {
            setTimeout(updateVisibleCartTotal, 500);
        });

        // Only subscribe if wp.data is defined
        if (typeof wp.data !== 'undefined') {
            wp.data.subscribe(() => {
                updateVisibleCartTotal();
            });
        }

        // Initial update on page load
        updateVisibleCartTotal();
    }
});
*/

// Category titles
document.addEventListener('DOMContentLoaded', function () {
    // Get all the category title containers
    const categoryTitleContainers = document.querySelectorAll('.category-title-container');
    
    // Loop through each category title container and move it outside the parent <li> element
    categoryTitleContainers.forEach(function (container) {
        // Find the closest <li> element to this title container
        const productListItem = container.closest('li');
        
        // If we found a valid <li> element, move the title above it
        if (productListItem) {
            productListItem.parentNode.insertBefore(container, productListItem);
        }
    });
});

// Dropdown filters for product categories
document.addEventListener("DOMContentLoaded", function () {
    if (document.body.classList.contains("woocommerce-shop")) {
        const sortDropdown = document.getElementById("sort-products");

        if (sortDropdown) {
            sortDropdown.addEventListener("change", function () {
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.set("orderby", this.value);
                window.location.search = urlParams.toString();
            });
        }
    }
});

// If no rating is available, add a greyed-out star rating container
/*
jQuery(document).ready(function ($) {
    $(".wc-block-product").each(function () {
        if ($(this).find(".wc-block-components-product-rating__container").length === 0) {
            $(this).find(".wp-block-woocommerce-product-price").before(`
                <div class="wc-block-components-product-rating__container">
                    <div class="wc-block-components-product-rating__stars wc-block-grid__product-rating__stars greyed-out" role="img" aria-label="No rating available">
                        <span></span>
                    </div>
                </div>
            `);
        }
    });
});
*/

// Archive / shop category view with toggleable filter menu
jQuery(document).ready(function ($) {
    if ($("body").hasClass("woocommerce-shop")) {
        $(".product-filter-menu").hide();
        
        // Append a span for the toggle symbol
        $("#toggle-filters").append(' <span class="toggle-sign">+</span>');

        $("#toggle-filters").on("click", function () {
            let sign = $(this).find(".toggle-sign");
            $(".product-filter-menu").stop(true, true).slideToggle(300);

            // Toggle between + and -
            sign.text(sign.text() === "+" ? "-" : "+");
        });
    }
});

// Hide the "Show Cart" button after adding to the cart (why is this even a thing?!)
jQuery(document).ready(function($) {
    // Hide the "Show Cart" button on page load in case it's already rendered
    $('.added_to_cart').closest('span').hide();
    
    $('body').on('added_to_cart', function() {
        $('.added_to_cart').closest('span').hide();
    });
});

// Product card popup window
jQuery(document).ready(function($) {
    if ($("body").hasClass("post-type-archive-product")) {
        // Append popup structure to body if not already present
        if ($(".custom-popup-container").length === 0) {
            $("body").append(`
                <div class="custom-popup-container">
                    <div id="custom-product-popup" class="custom-popup" style="display: none;">
                        <div class="custom-popup-content">
                            <span class="custom-popup-close">&times;</span>
                            <div class="product-title">
                                <div class="product-image">
                                    <img src="" id="popup-image" />
                                </div>
                                <div class="product-name">
                                    <h2 id="popup-title"></h2>
                                    <div id="popup-description" class="popup-short-description"></div>
                                </div>
                            </div>
                            <div class="ingredients">
                                <p id="ingredients_info"></p>
                            </div>
                            <div class="nutritions">
                                <h3 id="nutrition_text"></h3>
                                <div id="value-wrapper">
                                    <ul id="nutrition-items-list"></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            `);
        }

        // When a product link is clicked
        $('li.product').on('click', function(e) {
            e.preventDefault(); // Prevent default action (page reload)

            // Get the product ID from the data-wc-context attribute
            var productID = $(this).data('wc-context') ? $(this).data('wc-context').productId : null;

            // Get the product image URL
            var productImage = $(this).find('img').attr('src');

            // Make sure the product ID exists
            if (productID) {
                // Show the popup
                $('.custom-popup-container').fadeIn();

                // Update the product image in the popup
                $('#popup-image').attr('src', productImage);

                // Make the AJAX request to fetch ACF fields
                $.ajax({
                    url: popupAjaxData.ajax_url,
                    method: 'POST',
                    data: {
                        action: 'get_product_acf_data',  // The action we are calling in WordPress
                        nonce: popupAjaxData.nonce,     // Nonce for security
                        product_id: productID           // Send the product ID
                    },
                    success: function(response) {
                        console.log('AJAX Response:', response); // Log the response for debugging
                        if (response.success) {
                            $('#popup-title').html(response.data.product_title);

                             // Insert short description below title
                            if (response.data.short_description) {
                                $('#popup-description').html(response.data.short_description).show();
                            } else {
                                $('#popup-description').hide();
                            }

                            $('#ingredients_info').html(response.data.ingredients_info);
                            $('#nutrition_text').html(response.data.nutrition_text);

                            if (response.data.nutrition_items && response.data.nutrition_items.length > 0) {
                                var nutritionItemsHTML = '';
                                $.each(response.data.nutrition_items, function (index, item) {
                                    nutritionItemsHTML += `<li class="value-wrap ${item.indent_value ? 'indent' : ''}">
                                        <span class="value-name">${item.value_name}</span>
                                        <span class="value-amount">${item.value_amount}</span>
                                    </li>`;
                                });
                                $('#nutrition-items-list').html(nutritionItemsHTML);
                                $('.nutritions').show(); // Show the section if it has content
                            } else {
                                $('#nutrition-items-list').empty();
                                $('.nutritions').hide(); // Hide the section if it's empty
                            }
                        } else {
                            console.error('Error fetching ACF data:', response.data);
                        }
                    },
                    error: function(xhr, status, error) {
                        console.error('AJAX Error:', status, error);
                        console.error('Response:', xhr.responseText); // Check the full response
                    }
                });
            } else {
                console.log('No product ID found');
            }
        });

        // Prevent the popup from opening when "Add to Cart" button is clicked
        $('.add_to_cart_button').on('click', function(e) {
            // Make sure the add to cart button doesn't trigger the popup
            e.stopPropagation(); // This prevents the click event from bubbling up to the parent

            // Allow the default add to cart action to proceed
            return true;
        });

        // Close the popup when the close button is clicked
        $('.custom-popup-close').on('click', function() {
            $('.custom-popup-container').fadeOut(); // Hide the popup
        });

        // Close the popup when clicking outside the popup container
        $('.custom-popup-container').on('click', function(e) {
            // Check if the clicked target is the container (not the popup content)
            if ($(e.target).is('.custom-popup-container')) {
                $(this).fadeOut(); // Hide the popup
            }
        });
    }
});

/*
document.addEventListener("DOMContentLoaded", function () {
    document.querySelectorAll(".wc-block-components-product-button__button").forEach((button) => {
        let productId = button.getAttribute("data-product_id"); // Get the product ID

        if (productId) {
            // Create the quantity controls HTML
            let qtyControls = document.createElement("div");
            qtyControls.classList.add("custom-quantity-control");
            qtyControls.setAttribute("data-product-id", productId);
            qtyControls.innerHTML = `
                <button class="qty-btn qty-minus" data-product-id="${productId}">-</button>
                <span class="qty-display" id="qty-${productId}">1</span>
                <button class="qty-btn qty-plus" data-product-id="${productId}">+</button>
                <button class="add-to-cart-button" data-product-id="${productId}">Add</button>
            `;

            // Replace the original button with the quantity controls
            button.replaceWith(qtyControls);
        }
    });

    // Add event listeners for quantity controls
    document.querySelectorAll(".custom-quantity-control").forEach((control) => {
        let productId = control.getAttribute("data-product-id");
        let qtyDisplay = document.getElementById(`qty-${productId}`);
        let qty = 1;

        control.querySelector(".qty-plus").addEventListener("click", function () {
            qty++;
            qtyDisplay.textContent = qty;
        });

        control.querySelector(".qty-minus").addEventListener("click", function () {
            if (qty > 1) {
                qty--;
                qtyDisplay.textContent = qty;
            }
        });

        // AJAX Add to Cart
        control.querySelector(".add-to-cart-button").addEventListener("click", function () {
            fetch(wc_add_to_cart_params.ajax_url, {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams({
                    action: "woocommerce_ajax_add_to_cart",
                    product_id: productId,
                    quantity: qty,
                }),
            })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.fragments) {
                    document.dispatchEvent(new Event("wc_fragment_refresh"));
                }
            });
        });
    });
});

*/

// Product categories: check if product is OOS (also applies it to single product page)
document.addEventListener("DOMContentLoaded", function() {
    // Select all out of stock product items
    const outOfStockItems = document.querySelectorAll('.outofstock');

    outOfStockItems.forEach(function(item) {
        // Create the overlay element
        const overlay = document.createElement('div');
        overlay.className = 'out-of-stock-overlay'; // This is the outer div

        // Create a span or p for the text with a specific class
        const textElement = document.createElement('span'); // You can use 'p' instead if you prefer
        textElement.className = 'out-of-stock-text'; // Add your specific class here
        textElement.innerText = myTranslations.outOfStockText; // Use the localized string

        // Append the text element to the overlay
        overlay.appendChild(textElement);

        // Case 1: For product category view (single product image)
        const imageContainer = item.querySelector('.wc-block-components-product-image');
        if (imageContainer) {
            // Ensure the image container has relative positioning
            imageContainer.style.position = 'relative'; 
            imageContainer.appendChild(overlay); // Append the overlay to the image container
        }

        // Case 2: For product gallery (multiple images in a single product page)
        const galleryImageContainers = item.querySelectorAll('.woocommerce-product-gallery__image');
        galleryImageContainers.forEach(function(imageContainer) {
            // Ensure the image container has relative positioning
            imageContainer.style.position = 'relative'; 
            imageContainer.appendChild(overlay.cloneNode(true)); // Append the cloned overlay to each image container
        });
    });
});

// Add custom description to product category filter "Allergeenit"
jQuery(document).ready(function($) {
    $('#wpfBlock_3 .wpfCheckboxHier').before('<p class="filter-notice">' + myTranslations.organicFoodDisclaimer + '</p>');
    $('#wpfBlock_7 .wpfCheckboxHier').before('<p class="filter-notice">' + myTranslations.organicFoodDisclaimer + '</p>');
});

// Open minicart after user adds product to cart
/*
document.addEventListener("DOMContentLoaded", function () {
    console.log("✅ WooCommerce mini-cart script loaded!");

    // Listen for add to cart button click
    const addToCartButtons = document.querySelectorAll('.add_to_cart_button');

    addToCartButtons.forEach(button => {
        button.addEventListener('click', function () {
            console.log("🔘 Add to cart button clicked!");

            // Delay opening the mini-cart to ensure WooCommerce has updated the cart
            setTimeout(() => {
                console.log("⏳ Delaying mini-cart open to ensure cart is updated...");
                openMiniCart();
            }, 500); // Adjust delay if needed
        });
    });
});
function openMiniCart() {
    // Select the mini-cart button (wc-block-mini-cart__button)
    const miniCartButton = document.querySelector('.wc-block-mini-cart__button');

    if (miniCartButton) {
        // If button is found, simulate a click to open the mini-cart
        miniCartButton.click();
        console.log("🚀 Simulated click on mini-cart button to open the mini-cart.");
    } else {
        console.warn("⚠️ Mini-cart button not found.");
    }
}
*/

/******************
SINGLE PRODUCT PAGE
*******************/

// Single product page: check if product is OOS
document.addEventListener("DOMContentLoaded", function() {
    // Check if the body has the 'outofstock' class
    if (document.body.classList.contains('outofstock')) {
        // Select the product image gallery
        const imageGallery = document.querySelector('.woocommerce-product-gallery__wrapper');

        if (imageGallery) {
            // Create the overlay element
            const overlay = document.createElement('div');
            overlay.className = 'out-of-stock-overlay'; // Same class as before

            // Create a span for the text with a specific class
            const textElement = document.createElement('span'); // You can also use 'p' if preferred
            textElement.className = 'out-of-stock-text'; // Same class as before
            textElement.innerText = myTranslations.outOfStockText; // Use the localized string

            // Append the text element to the overlay
            overlay.appendChild(textElement);

            // Position the overlay over the image gallery
            imageGallery.style.position = 'relative'; // Ensure the image container has relative positioning
            imageGallery.appendChild(overlay); // Append the overlay to the image gallery
            
            // Optionally, grey out the images in the gallery
            const images = imageGallery.querySelectorAll('img');
            images.forEach(function(img) {
                img.style.filter = 'grayscale(100%)'; // Grey out the image
                img.style.opacity = '0.5'; // Adjust visibility if needed
            });
        }
    }
});

document.addEventListener("DOMContentLoaded", () => {
    const paymentMessage = document.getElementById("payment-method-message");
    const productPrice = document.querySelector(".wp-block-woocommerce-product-price");
  
    if (paymentMessage && productPrice) {
      // Move the payment message after the product price
      productPrice.after(paymentMessage);
    }
});

/************
CHECKOUT PAGE
*************/

jQuery(function($) {
    // When the checkbox is clicked
    $('#add_puuhaboksi').change(function() {
        // Save checkbox state in localStorage
        if ($(this).is(':checked')) {
            localStorage.setItem('puuhaboksi_checked', 'yes');
        } else {
            localStorage.removeItem('puuhaboksi_checked');
        }

        // Trigger a reload after adding the product, but without form submission
        location.reload(); // Reload the page
    });

    // On page load, check if the checkbox state is stored
    if (localStorage.getItem('puuhaboksi_checked') === 'yes') {
        $('#add_puuhaboksi').prop('checked', true); // Recheck the checkbox
    }
});


// Add custom title and description to child name and birthday fields
document.addEventListener("DOMContentLoaded", function () {
    const checkElement = setInterval(() => {
        const targetElement = document.querySelector(".wc-block-components-address-form__acf-field_673da54a4ad88");

        if (targetElement) {
            clearInterval(checkElement); // Stop checking when the target is found
            
            if (!document.querySelector('.child-info')) {
                // Create the new message element
                const newHeading = document.createElement("h3");
                const newText = document.createElement("p");

                // Use localized strings
                newHeading.textContent = myTranslations.childInfoHeading;
                newText.textContent = myTranslations.childInfoDescription;

                newHeading.className = "child-info";
                newText.className = "child-info";

                // Insert the new text above the target element
                targetElement.parentNode.insertBefore(newHeading, targetElement);
                targetElement.parentNode.insertBefore(newText, targetElement);
            }
        }
    }, 500);
});

// Set accepted data formats and ranges for the child's birthday input fields
jQuery(document).ready(function ($) {
    // Modify the Birth Month field
    jQuery(document).ready(function($) {
        const birthMonthField = $('#contact-acf-field_673da6bc4ad89');
        if (birthMonthField.length) {
            birthMonthField.attr({
                type: 'number', // Change input type to number
                min: 1,         // Minimum value
                max: 12,        // Maximum value
            });
    
            // Add validation to enforce numeric range
            birthMonthField.on('input', function() {
                const value = $(this).val();
    
                // Allow only numbers and restrict to range 1-12
                if (value && (!/^\d+$/.test(value) || value < 1 || value > 12)) {
                    $(this).val(''); // Clear invalid input
                }
            });
    
            // Add additional check on losing focus
            birthMonthField.on('change', function() {
                const value = $(this).val();
    
                // Reset the value if it's out of range
                if (value && (value < 1 || value > 12)) {
                    $(this).val('');
                }
            });
        }
    });

    // Modify the Birth Year field
    const birthYearField = $('#contact-acf-field_673da6d24ad8a');
    if (birthYearField.length) {
        const currentYear = new Date().getFullYear(); // Get the current year
        birthYearField.attr({
            type: 'number',              // Change input type to number
            min: 2000,                   // Minimum value
            max: currentYear,            // Maximum value
            //placeholder: `e.g., ${currentYear}`, // Placeholder text
            value: currentYear,          // Default value
        });
    }
});

// Process bundle box contents in cart and remove quantity controls and replace them with quantity indicator (xValue)
document.addEventListener('DOMContentLoaded', () => {
    // Check if the current URL includes "ostoskori"
    if (!window.location.href.includes('ostoskori')) {
        return; // Exit if not on the cart page
    }

    // Function to process bundled items
    const processBundledItems = () => {
        const bundledItems = document.querySelectorAll('.is-bundled__indented');

        bundledItems.forEach((item) => {
            const productName = item.querySelector('.wc-block-components-product-name');
            const quantityInput = item.querySelector('.wc-block-components-quantity-selector__input');

            if (productName && quantityInput) {
                const quantity = quantityInput.value || 0;

                let quantityDisplay = productName.querySelector('.quantity-display');
                if (!quantityDisplay) {
                    quantityDisplay = document.createElement('span');
                    quantityDisplay.className = 'quantity-display';
                    productName.appendChild(quantityDisplay);
                }

                quantityDisplay.textContent = ` x${quantity}`;
                quantityInput.style.display = 'none';
            }
        });

        // Disable all links by preventing the default behavior
        const allLinks = document.querySelectorAll('.wc-block-components-product-name');
        allLinks.forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault(); // Prevent navigation
                console.log('Link click prevented:', link.href); // Optional: Log the prevented link
            });
        });
    };

    // Create a MutationObserver
    const observer = new MutationObserver((mutationsList, obs) => {
        let shouldProcess = false;

        mutationsList.forEach((mutation) => {
            if (mutation.type === 'childList') {
                shouldProcess = true;
            }
        });

        if (shouldProcess) {
            obs.disconnect(); // Temporarily stop observing
            processBundledItems(); // Process the items
            obs.observe(targetNode, { childList: true, subtree: true }); // Restart observing
        }
    });

    const targetNode = document.body; // Adjust if necessary
    observer.observe(targetNode, { childList: true, subtree: true });

    // Initial processing
    processBundledItems();
});

// Reorder add Puuhaboksi to cart to be the highest option in checkout
jQuery(document).ready(function($) {
    $('.add_puuhaboksi_section').prependTo('#kco-order-review');
});

/*********
MY ACCOUNT
**********/

// CANCEL SUBSCRIPTION POPUP
jQuery(document).ready(function ($) {
    if (window.location.pathname.startsWith('/oma-tili/view-subscription/')) {    // This will need multilingual support later on
        
        /*
        document.querySelectorAll('.bundled_table_item_subtotal').forEach((item) => {
            console.log("subtotal item found");
            const text = item.innerHTML;
            // Remove "joka 2 kuukausi" or similar patterns
            item.innerHTML = text.replace(/joka\s2\skuukausi/, '').trim();
        });
        */
        
        // Append popup HTML to the body
        const popupHTML = `
            <div id="cancelPopup" class="popup-overlay">
                <div class="popup-content">
                    <span class="title">${myTranslations.cancelMessage}</span>
                    <span class="description">${myTranslations.benefitsMessage}</span>
                    <div class="popup-actions">
                        <button id="closePopup" class="button close">${myTranslations.close}</button>
                        <button id="proceedCancel" class="button proceed">${myTranslations.yesCancel}</button>
                    </div>
                </div>
            </div>`;
        $("body").append(popupHTML);

        // Show the popup on cancel link click
        $(".cancel.wcs_block_ui_on_click").on("click", function (e) {
            e.preventDefault();
            $("#cancelPopup").fadeIn().css("display", "flex");

            // Show the overlay (blockUI) when popup is triggered
            $("body").append('<div class="blockUI blockOverlay"></div>'); // Add the block UI overlay
        });

        // Proceed with cancellation
        $(document).on("click", "#proceedCancel", function () {
            const cancelHref = $(".cancel.wcs_block_ui_on_click").attr("href");
            window.location.href = cancelHref; // Redirect to cancel link
        });

        // Close the popup
        $(document).on("click", "#closePopup", function () {
            $("#cancelPopup").fadeOut(function () {
                // Ensure all functionality is restored
                $(this).css("display", "none");
            });

            // Remove or hide the blocking overlay
            $(".blockUI.blockOverlay").remove(); // Remove the overlay
        });
    }
});