jQuery(document).ready(function($) {
    //console.log("Backend customization scripts loaded!");
});

/******** 
GUTENBERG
*********/

if (typeof wp !== "undefined" && wp.blocks && document.body.classList.contains("block-editor-page")) {
    wp.domReady(() => {
        wp.blocks.registerBlockStyle('core/button', {
            name: 'pink-button',
            label: 'Pinkki nappula'
        });
    });
}
